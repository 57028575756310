var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"app-container"},[_c('baseTable',{ref:"table",staticClass:"title-table",attrs:{"isInit":_vm.list.length,"list":_vm.list,"option":_vm.tableOption,"api":_vm.getList,"resetMergeData":_vm.resetMergeData,"span-method":_vm.handleSpanMethod},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getName(row))+" ")]}},{key:"structName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"radio-group"},_vm._l((_vm.filterStructs(row)),function(ref){
var name = ref.name;
var id = ref.id;
return _c('el-tag',{key:id,staticClass:"tag-medium radio-item",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(name)+" ")])}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }