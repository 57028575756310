<template>
  <section v-loading="loading" class="app-container">
    <baseTable
      ref="table"
      class="title-table"
      :isInit="list.length"
      :list="list"
      :option="tableOption"
      :api="getList"
      :resetMergeData="resetMergeData"
      :span-method="handleSpanMethod"
    >
      <template #name="{ row }">
        {{ getName(row) }}
      </template>
      <template #structName="{ row }">
        <div class="radio-group">
          <el-tag v-for="{ name, id } in filterStructs(row)" :key="id" class="tag-medium radio-item" type="primary">
            {{ name }}
          </el-tag>
        </div>
      </template>
    </baseTable>
  </section>
</template>

<script>
import { tableOption } from './const'
import priceApi from '@/api/product/price'
import { baseTable } from '@/components/base/baseTable/mixins'
import { mapState } from 'vuex'
import { getSizeLevelCategoryPriceList } from '@/views/product/utils'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  mixins: [baseTable],
  data() {
    this.getList = priceApi.list
    return {
      loading: false,
      column: [],
      dataFilterForm: {}
    }
  },
  computed: {
    ...mapState({
      list(state) {
        return state.dic.gearsCategory.partData.enabled || []
      }
    }),
    resetMergeData() {
      return {
        levelCategoryId: this.curTabItem.value,
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: false, column: 'id' }
        ]
      }
    },
    tableOption() {
      return {
        ...tableOption,
        column: [...tableOption.column, ...this.column]
      }
    },

    filterStructs({ dataFilterForm: { structName } }) {
      return (row) => {
        return (row.$productInfo.styleList || []).filter(({ name }) => {
          if (!structName) return true
          structName.lastIndex = 0
          return structName.test(name)
        })
      }
    }
  },
  async created() {
    this.loading = true
    await this.$store.dispatch('GetDic', 'gearsCategory')
    this.loading = false
  },
  methods: {
    afterInit(res, postData) {
      let { structName } = postData
      this.$set(this.dataFilterForm, 'structName', structName ? new RegExp(structName.trim(), 'img') : '')
    },
    getName(data) {
      return data.$productInfo?.styleList?.[0]?.name
    },
    handleTableData(data) {
      let tempArr = []
      data.forEach((row) => {
        let priceObj = getSizeLevelCategoryPriceList(row.sizeList).reduce((prev, next) => {
          prev[next.id] = next
          return prev
        }, {})

        row.sizeList?.forEach((size, index) => {
          size.$productInfo = row
          size.$sizeIndex = index

          Object.assign(size, priceObj[size.id])

          tempArr.push(size)
        })
      })

      this.column = ($MAP(tempArr, 'priceList').filter((item) => !validatenull(item))[0] || []).map((item) => {
        item.prop = `${item.prop}`
        return item
      })

      return tempArr
    },
    handleSpanMethod({ row, column }) {
      if (['$productInfo.chineseName', 'structName'].includes(column.property)) {
        if (row.$sizeIndex === 0) {
          return [row.$productInfo.sizeList.length, 1]
        } else {
          return [0, 0]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
