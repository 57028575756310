export const tableOption = {
  menu: false,
  searchLabelWidth: 'auto',
  column: [
    {
      label: '产品名称',
      prop: '$productInfo.chineseName'
    },
    {
      label: '款式',
      prop: 'styleName',
      search: true,
      hide: true
    },
    {
      label: '款式',
      prop: 'structName'
    },
    {
      label: '产品名称',
      prop: 'chineseName',
      hide: true,
      search: true
    },
    {
      label: '尺码',
      prop: 'sizeName'
    },
    {
      label: '最低售价',
      prop: 'lowerLimitPrice'
    }
  ]
}
