import { apiFactory } from '@/utils/constant'

const priceApi = {
  list: '/prototypeService/productPrototype/list',
  priceList: '/productService/productPrototype/list', // 价格列表
  importPrice: '/productService/sizeLevelCategoryPrice/batchImport', // 档位价格导入
  exportPrice: {
    url: '/productService/sizeLevelCategoryPrice/batchExport',
    responseType: 'blob'
  } // 档位价格导出
}

for (const key in priceApi) {
  priceApi[key] = apiFactory(priceApi[key])
}

export default priceApi
